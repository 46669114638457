// export const myVar = 'This is my variable'
// export const settings = {
//   localFrontUrlPath: 'http://localhost:8080',
//   localBackendUrlPath: 'http://localhost:8080',
//   realUrlPath: 'http://www.cosbody.com'
// }
// export default myVar = 'This is my variable'
export default {
  localFrontUrlPath: "http://localhost:8080",
  localBackendUrlPath: "http://localhost:9000/api",

  domainBackendUrl9000Path: "http://cosbody.com:9000/api",

  realFrontendUrlPath: "http://cosbody.com",
  realBackendUrlPath: "http://cosbody.com/api"
};
