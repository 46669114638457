import "@mdi/font/css/materialdesignicons.css"; // Ensure you ar
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are us
import "font-awesome/css/font-awesome.min.css"; // Ensure you are using css-loader
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Vue from "vue";
import Vuetify from "vuetify/lib";
require("vuetify/dist/vuetify.min.css");

Vue.component("font-awesome-icon", FontAwesomeIcon); // Register component globally
library.add(fas); // Include needed icons

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    // iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    // iconfont: 'mdi', // default - only for display purposes
    // iconfont: 'faSvg',
    iconfont: "fa4",
    values: {
      product: "mdi-dropbox",
      support: "mdi-lifebuoy",
      steam: "mdi-steam-box",
      pc: "mdi-desktop-classic",
      xbox: "mdi-xbox",
      playstation: "mdi-playstation",
      switch: "mdi-nintendo-switch"
    }
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#CBAA5C",
        secondary: "#083759",
        // primary: "#ee44aa",
        // secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 1280
    },
    scrollBarWidth: 24
  }
});
