import axios from "axios";
import varConifg from "./variables.js";
// console.log(varConifg.localFrontUrlPath);
// console.log(varConifg.localBackendUrlPath);
// console.log(varConifg.realUrlPath);

export default axios.create({
  baseURL: varConifg.realBackendUrlPath,
  // baseURL: varConifg.localBackendUrlPath,

  // timeout: 1000,
  headers: {
    "Content-type": "application/json;charset=utf-8",

    "Access-Control-Allow-Origin": varConifg.realFrontendUrlPath
    // 'Access-Control-Allow-Origin': varConifg.localFrontUrlPath,

    // 'Access-Control-Allow-Origin': 'http://localhost:8080',

    // "Content-type": "application/json",
    // "Content-Type": "multipart/form-data",
    // "X-Content-Type-Options":"nosniff",
    // "X-Content-Type-Options":"Deny",
    // 'Access-Control-Allow-Origin': varConifg.$localFrontUrlPath,
    // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // 'X-Custom-Header': 'foobar'
  }
});

// console.log('realFrontendUrlPath>'+varConifg.realFrontendUrlPath)
// console.log('realBackendUrlPath>'+varConifg.realBackendUrlPath)

// .request({
//   baseURL: 'http://localhost:9000/api',
//   // method: 'post',
//   // data: {}
// }).then(response => console.log(response.headers['Content-Type']));

// export const AXIOS = axios.create({
//  baseURL: `http://localhost:9000`,
//  headers: {
//   "Content-Type": "application/json",
//    'Access-Control-Allow-Origin': 'http://localhost:8080'
//  }
// })

// const AXIOS = axios.create({
//   baseURL: `http://localhost:9000`,
//   // baseURL: `/api`,
//   // timeout: 1000,
//   headers: {
//     'Access-Control-Allow-Origin': 'http://localhost:8080',
//     'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//     "Content-Type": "application/json",
//       // 'Cache-Control': null,
//       // 'X-Requested-With': null,
//   }
// });

// export default {
// }
