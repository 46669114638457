<template>
  <nav>
    <!-- <v-card
      class="mx-auto overflow-hidden"
      height="800"
      max-height="1000"
      width="300"
   > -->

    <div id="app" class="mt-0 ">
      <v-app-bar app height="80" color="white dark" hide-on-scroll>
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
          class="black--text"
        ></v-app-bar-nav-icon>

        <v-spacer></v-spacer>

        <v-img
          :src="require('../assets/images/logo.png')"
          height="80"
          max-width="250"
          max-height="80"
          contain
          v-on:click="redirectToHome"
        ></v-img>
        <v-spacer></v-spacer>
      </v-app-bar>
      <!-- 
      mx-auto overflow-hidden
      height=""
      max-height="1000"
      :disable-resize-watcher="disableResizeWatcher" 
      :permanent="permanent"
      style="position:fixed; top:0; left:0; overflow-y:scroll;"  
      width="250"
 -->

      <v-navigation-drawer app v-model="drawer" class=" gray dark" fixed>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title black--text">
              COSBODY<br />
            </v-list-item-title>
            <v-list-item-subtitle class="black--text">
              수기치료 기공치료<br />
              박인호건강증진클리닉
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <!--
              open-all
              return-object
              hoverable
              return-object
              hoverable:true 
              @update:active="goPage"
              selection-type='independent'
              expand-icon="mdi-chevron-down"
              on-icon="mdi-bookmark"
              off-icon="mdi-bookmark-outline"
              indeterminate-icon="mdi-bookmark-minus"
              open-all
              hoverable
                
                :return-object="returnObject"
              -->
        <v-treeview
          class="mt-0"
          v-model="tree"
          :items="items"
          :open="open"
          :open-on-click="openOnClick"
          :item-key="itemKey"
          activatable
          :dense="dense"
          :shaped="shaped"
          :rounded="rounded"
          :hoverable="hoverable"
          :load-children="getChildTree"
          transition
        >
          <template slot="label" slot-scope="props">
            <router-link
              style="text-decoration: none; color: inherit;"
              :to="props.item.to"
              v-if="props.item.to"
            >
              {{ props.item.name }}
            </router-link>
            <span v-else>{{ props.item.name }}</span>
          </template>
        </v-treeview>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <!-- <v-divider vertical></v-divider> -->
        <!-- <v-icon>mdi-account</v-icon>LogOut -->
        <!-- <v-divider></v-divider>

              
            <div v-if="currentUser" class="navbar-nav ml-auto" >
                <li class="nav-item">
                  <a class="custom-router-link-exact-active" href @click.prevent="logOut">
                    <font-awesome-icon icon="sign-out-alt" /> LogOut
                  </a>
                </li>
            </div>

          <div v-if="!currentUser" class="navbar-nav ml-auto" >
              <li class="nav-item">
                <router-link to="/login" class="custom-router-link-exact-active">
                  <font-awesome-icon icon="sign-in-alt" /> Admin Login
                </router-link>
              </li>
          </div> -->
      </v-navigation-drawer>
    </div>
  </nav>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      bottomNav: true,
      drawer: false,
      tree: [],
      classes: [],
      open: ["1", "2","3"],
      show: true,
      files: {
        html: "mdi-language-html5",
        js: "mdi-nodejs",
        json: "mdi-json",
        md: "mdi-markdown",
        pdf: "mdi-file-pdf",
        png: "mdi-file-image",
        txt: "mdi-file-document-outline",
        xls: "mdi-file-excel"
      },
      openOnClick: true,
      itemKey: "id",
      //  activatable: true,
      shaped: false,
      rounded: true,
      dense: true,
      hoverable: true,
      returnObject: false,
      disableResizeWatcher: false,
      permanent: false,
      userInfo: this.$store.state.auth.user,
      childTree1: [],
      childTree2: [],
      childTree3: []
      // hover: false,
    };
  },
  created() {},
  computed: {
    selected() {
      console.log("computed-selected");
      return "computed-selected";
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    items() {
      // console.log("computed-items");
      return [
        {
          id: "1",
          name: "건강클리닉",
          children: this.childTree1
        },
        {
          id: "2",
          name: "건강 프로그램",
          children: this.childTree2
        },
        {
          id: "3",
          name: "박인호기공지도사",
          children: this.childTree3
        },
        // {
        //   id: "3",
        //   name: "케어사례",
        //   menu_type: "케어사례",
        //   to: "/bbslist/1"
        // },
        {
          id: "4",
          name: "오시는길",
          menu_type: "오시는길",
          to: "/4-1"
        }
      ];
    },
    selected() {}
  },
  watch: {
    selected: "randomAvatar"
  },
  methods: {
    randomAvatar() {
      return "randomAvatar";
    },
    getComposeChild(id) {
      if (id === "1") {
        this.childTree1 = [
          { id: "11", name: "Ⅰ 인사말", menu_type: "건강클리닉", to: "/1-1" },
          { id: "12", name: "Ⅱ 프로필", menu_type: "건강클리닉", to: "/1-2" },
          { id: "13", name: "Ⅲ 자신감", menu_type: "건강클리닉", to: "/1-3" },
          { id: "14", name: "Ⅳ 예약안내", menu_type: "건강클리닉", to: "/1-4" }
        ];
        return this.childTree1;
      }
      if (id === "2") {
        this.childTree2 = [
          {id: "21", name: "Ⅴ 통증관리", menu_type: "건강프로그램", to: "/2-1"},
          {id: "22", name: "Ⅵ 자세교정", menu_type: "건강프로그램", to: "/2-2"},
          {id: "23", name: "Ⅶ 생활습관병", menu_type: "건강프로그램", to: "/2-3"},
          {id: "24", name: "Ⅷ 재활/스포츠의학", menu_type: "건강프로그램", to: "/2-4" },
        ];
        return this.childTree2;
      }
      if (id === "3") {
        this.childTree3 = [
          {id: "31", name: "Ⅸ 수기치료", menu_type: "박인호기공지도사", to: "/3-1"},
          {id: "32", name: "Ⅹ 기공치료", menu_type: "박인호기공지도사", to: "/3-2"},
          {id: "33", name: "Ⅺ 운동처방", menu_type: "박인호기공지도사", to: "/3-3"},
          {id: "34", name: "Ⅻ 자격연수", menu_type: "박인호기공지도사", to: "/3-4" },
        ];
        return this.childTree3;
      }
    },
    getChildTree(item) {
      item.children.push(this.getComposeChild(item.id));
    },
    randomAvatar() {
      // console.log('methods-randomAvatar()-watch seleted call');
    },
    logOut() {
      // console.log('navbar logout method');
      // console.log('this.$router.currentRoute>',this.$router.currentRoute);
      // console.log('this.$router.currentRoute.name>',this.$router.currentRoute.name);
      this.$store.dispatch("auth/logout");

      if (this.$router.currentRoute.name !== "home") {
        this.$router.push("/");
      }
    },
    redirectToHome: function() {
      window.location.href = this.$pathUrl;
    },
    goPage: function(item) {
      console.log("gopage");
      {
        if (item.length > 0)
          // console.log(item[0].name)
          console.log(item);
      }
    },
    hoverOver: function() {
      console.log("over");
      // this.classes = ['fade', 'bounceUp']
      this.classes = ["animated", "bounceIn"];
    },
    hoverOut: function() {
      console.log("out");
      this.classes = [];
    },
    hoverOutTimeout: function() {
      setTimeout(() => {
        console.log("timeout");
        this.classes = [];
      }, 1000);
    }
  }
};
</script>
<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

a {
  color: inherit;
}

.router-link-exact-active {
  background: white;
  border-radius: 5px;
  color: black;
  font-style: italic;
}

.custom-router-link-exact-active {
  text-decoration: none;
  background: white;
  border-radius: 5px;
  color: inherit;
  /* font-style: italic; */
}
</style>
