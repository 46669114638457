// import axios from 'axios';
import http from "../js/http-commons";

const API_URL = "/auth/";

class AuthService {
  login(user) {
    // console.log("###auth.service-login");
    return http
      .post(
        API_URL + "signin",
        {
          username: user.username,
          password: user.password
        }
        // {timeout: 0.5}
      )
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      })
      .catch(err => {
        // console.log(err.code);
        // console.log(err.message);
        // console.log(err.stack);
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(user) {
    return http.post(API_URL + "signup", {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
