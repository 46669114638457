<template>
  <!-- <nav> -->
  <!-- <v-app> -->

  <div class="main mt-5">
    <!-- <iframe
            allowfullscreen
            frameborder="0"
            height="500"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15951967583!2d-74.12010542954445!3d40.69766333203375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY!5e0!3m2!1sen!2sus!4v1566422061048!5m2!1sen!2sus"
            style="border:0"
            width="100%"
          /> -->

    <v-card class="mx-auto" max-width="700">
      <!-- 
          <v-container fluid class="greay-bg my-5">
          -->
      <v-container fluid>
        <v-row dense>
          <v-col cols="12">
            <v-card>
              <v-img
                :src="require('../assets/images/4-1.png')"
                class="white--text align-end"
              >
              </v-img>

              <v-img
                :src="require('../assets/images/4-1-2.png')"
                class="white--text align-end"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
  <!-- </v-app> -->
  <!-- </nav> -->
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
