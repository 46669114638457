<template>
  <!-- <nav> -->
  <!-- <v-app> -->

  <div class="main mt-5">
    <v-card class="mx-auto" max-width="700">
      <!-- 
          <v-container fluid class="greay-bg my-5">
          -->
      <v-container fluid>
        <v-row dense>
          <v-col cols="12">
            <v-card>
              <v-img
                :src="require('../assets/images/1-1.png')"
                class="white--text align-end"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
  <!-- </v-app> -->
  <!-- </nav> -->
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
