<template>
  <!-- <nav> -->
  <!-- <v-app> -->

  <div class="main mt-5">
    <v-card class="mx-auto" max-width="700">
      <!-- 
          <v-container fluid class="greay-bg my-5">
          -->
      <v-container fluid>
        <v-row dense>
          <v-col cols="13">
            <v-card>
              <v-img
                :src="require('../assets/images/1-2.png')"
                class="white--text align-end"
              >
                <a
                  href="http://www.riss.kr/index.do"
                  style="position:absolute; top: 28%; right: 3%; height:1%; width: 25%;"
                  target="_blank"
                ></a>
                <!-- <a  href="http://www.riss.kr/index.do"  style="position:absolute; top: 24%; right: 10%; height:1%; width: 20%; background-color:black;"></a> -->
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
  <!-- </v-app> -->
  <!-- </nav> -->
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
