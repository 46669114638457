<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> </v-card-title>

        <v-card-text>
          1시간 대기 상태로<br />{{ second }}초 후에 로그아웃 됩니다.<br />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      time: 60000 //60000 -> 60 seconds
    };
  },
  created() {
    //  console.log('modalIdle created');

    if (this.currentUser) {
      this.dialog = true;

      let timerId = setInterval(() => {
        this.time -= 1000;
        // console.log('this.time>>>',this.time)
        // console.log(this.$store.state.idleVue.isIdle)
        if (!this.$store.state.idleVue.isIdle) {
          clearInterval(timerId);
        }

        if (this.time < 1) {
          clearInterval(timerId);
          // console.log('go gogo logout');
          this.dialog = false;
          this.logOut();
        }
      }, 1000);
    }
  },
  methods: {
    logOut() {
      // console.log('modalIdle-logout-method');
      this.$store.dispatch("auth/logout");
      alert("로그아웃 되었습니다.");
      if (this.$router.currentRoute.name !== "home") {
        this.$router.push("/");
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    second() {
      return this.time / 1000;
    }
  }
};
</script>
<style scoped>
/* .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  width: 500px;
  box-shadow: 1px 2px 4px rgba(153, 155, 168, 0.12);
  border-radius: 4px;
  @apply bg-white p-2;
}

.modal__title {
  color: #38404f;
  @apply flex items-center justify-between p-3 font-bold;
} */
</style>
