<template>
  <div id="mainvue">
    <!-- <nav> -->
    <!-- <v-app> -->

    <!-- <banner /> -->
    <!-- <div class="main mt-5"> -->
    <!-- class="mx-auto" -->
    <div class="mt-5">
      <!-- <div v-if="$isMobile()">MOBILE</div>
    <div v-else>DESKTOP OR TABLET</div> -->

      <!-- {{ msg }} -->

      <!-- width="400" -->
      <v-card class="mx-auto" max-width="700">
        <!-- <v-system-bar
      color="indigo darken-2"
      dark
    >
      <v-spacer></v-spacer>

      <v-icon>mdi-window-minimize</v-icon>

      <v-icon>mdi-window-maximize</v-icon>

      <v-icon>mdi-close</v-icon>
    </v-system-bar> -->

        <!-- <h1 class="subheading grey--text">홈</h1> -->

        <!-- 
      <v-container fluid class="greay-bg my-5">
        
       -->
        <v-container>
          <!-- <div class="max-w-2xl mx-auto py-16">
      <p>Is it Idle? - <span class="font-bold">{{isIdle}}</span></p>
      <ModalIdle v-if="isIdle"/>
      	<router-view /> -->
          <!-- </div> -->
          <v-row dense>
            <!-- <v-row > -->
            <!-- <v-col
            v-for="card in cards"
            :key="card.title"
            :cols="card.flex"
           
          > -->

            <v-col cols="12">
              <v-card color="#85C1E9" dark>
                <!-- src = "@/assets/images/main.png" -->
                <v-img
                  v-bind:src="require('../assets/images/main.png')"
                  class="white--text align-end"
                >
                </v-img>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card color="white" dark>
                <v-img
                  v-bind:src="require('../assets/images/main-1.png')"
                  class="white--text align-end"
                >
                </v-img>
                <!-- <v-card-actions> -->
                <!-- <v-btn text></v-btn> -->
                <!-- </v-card-actions> -->
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card color="#85C1E9" dark>
                <v-img
                  v-bind:src="require('../assets/images/main-2.png')"
                  class="white--text align-end"
                >
                </v-img>
                <v-card-actions>
                  <v-btn width="100%" text size="3" @click="onClickMain_1">
                    <font size="3">자세히 보기</font>
                    </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <!-- <v-dialog :fullscreen="$vuetify.breakpoint.sm"> -->
      <!-- <v-row justify="center">
  <v-dialog v-model="dialog" persistent max-width="290">
    <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
 </template>
      <v-card>
        <v-card-title class="headline">Use Google's location service?</v-card-title>
        <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Disagree</v-btn>
          <v-btn color="green darken-1" text @click="dialog = false">Agree</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row> -->
    </div>
    <!-- </v-app> -->
    <!-- </nav> -->
  </div>
</template>

<script>
import { isMobile } from "vue-mobile-detection";
import ModalIdle from "@/components/ModalIdle";

export default {
  name: "mainvue",
  components: {
    Banner: () => import("@/components/Banner"),
    ModalIdle
  },
  computed: {
    isIdle() {
      console.log("" + this.$store.state.idleVue.isIdle);
      return this.$store.state.idleVue.isIdle;
    }
  },
  created() {
    // console.log('created');
    // let timerId = setInterval(() => {
    //     this.time -= 1000;
    //     console.log('this.time>',this.time)
    //     console.log('isIdle>',this.$store.state.idleVue.isIdle)
    //     if (!this.$store.state.idleVue.isIdle){
    //       clearInterval(timerId);
    //     }
    //     if (this.time < 1) {
    //       clearInterval(timerId);
    //       alert('logout user....');
    //     }
    // }, 1000);
    // console.log(this.$isMobile());
  },
  data() {
    return {
      response: [],
      errors: [],
      time: 10000,

      msg: isMobile
        ? "Welcome to Your Vue.js mobile App!"
        : "Welcome to Your Vue.js App",
      dialog: false,
      model: "caption",
      sizes: [
        ["mdi-devices", "all", "caption"],
        ["mdi-cellphone-iphone", "sm", "body-2"],
        ["mdi-laptop", "md", "body-1"],
        ["mdi-monitor", "lg", "h6"],
        ["mdi-television", "xl", "h4"]
      ],
      items: [
        // {
        //   color: '#1F7087',
        //   src: 'new-tempMain.jpg',
        //   title: 'Supermodel',
        //   artist: 'Foster the People',
        // },
        // {
        //   color: '#952175',
        //   src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
        //   title: 'Halcyon Days',
        //   artist: 'Ellie Goulding',
        // },
      ],
      show: true,
      isMobile: true
    };
  },
  methods: {
    onClickMain_1() {
      // console.log('urlpath >>> ' + this.$store.pathUrl.urlAddr);
      // console.log('urlpath >>> ' + this.$pathUrl);
      // window.location.href = this.$pathUrl+"/2-1";
      this.$router.push("3-4");
      // window.location.href = "http://localhost:8080/intro";
    }
  }
  //   beforeDestroy () {
  //       if (typeof window !== 'undefined') {
  //         window.removeEventListener('resize', this.onResize, { passive: true })
  //       }
  //     },

  //   mounted () {
  //     this.onResize()
  //     window.addEventListener('resize', this.onResize, { passive: true })
  //      console.log(this.$vuetify.breakpoint.name)
  //   },

  //   methods: {
  //     onResize () {
  //       this.isMobile = window.innerWidth < 600
  //     },
  //   },
  // computed: {

  //   imageHeight () {
  //   console.log('main computed')
  //   console.log(this.$vuetify.breakpoint.name)
  //   switch (this.$vuetify.breakpoint.name) {
  //     case 'xs': return '220px'
  //     case 'sm': return '400px'
  //     case 'md': return '500px'
  //     case 'lg': return '600px'
  //     case 'xl': return '800px'
  //   }
  // },
  // },
  // data: () => ({
  //   cards: [
  //     { title: '', src: 'new-tempMain.jpg', flex: 12 },
  //     // { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 12 },
  //     // { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
  //     // { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
  //     // { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 12 },
  //     // { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
  //     // { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
  //     // { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 12 },
  //     // { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
  //     // { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
  //   ],
  // }),
};
</script>

<style scoped>
body {
  /* // font grows 1px for every 100px of viewport width */
  font-size: calc(16px + 1vw);
  /* // leading grows along with font,
  // with an additional 0.1em + 0.5px per 100px of the viewport */
  line-height: calc(1.1em + 0.5vw);
}
</style>
