<template>
  <v-card class="mx-auto" max-width="700" tile flat>
    <v-container>
      <div class="col-md-12">
        <div class="card card-container">
          <form name="form" @submit.prevent="handleLogin">
            <div class="form-group">
              <label for="username">USER ID</label>
              <input
                v-model="user.username"
                v-validate="'required'"
                type="text"
                class="form-control"
                name="username"
              />
              <div
                v-if="errors.has('username')"
                class="alert alert-danger"
                role="alert"
              >
                USER ID는 필수 입력 입니다.
              </div>
            </div>
            <div class="form-group">
              <label for="password">PASSWORD</label>
              <input
                v-model="user.password"
                v-validate="'required'"
                type="password"
                class="form-control"
                name="password"
                autocomplete="on"
              />
              <div
                v-if="errors.has('password')"
                class="alert alert-danger"
                role="alert"
              >
                PASSWORD는 필수 입력 입니다.
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary btn-block" :disabled="loading">
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                <span>Login</span>
              </button>
            </div>
            <div class="form-group">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import User from "../models/user";

export default {
  inject: ["$validator"],
  //  inject: {
  //   $validator: '$validator'
  // },
  name: "login",
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: ""
    };
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },

  watch: {
    watched() {}
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            response => {
              if (response) {
                this.$router.push("/");
              } else {
                this.loading = false;
                this.message = "ID 또는 비밀번호를 확인 하세요.";
              }
            },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
