<template>
  <div id="app" class="main mt-0">
    <!-- <v-container fluid
   > -->

    <!--
   hide-on-scroll 
   src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
-->
    <v-bottom-navigation v-model="bottomNav" class="white " height="120px">
      <!-- <h3>{{ response }}</h3> -->

      <!-- <div class="black--font-weight-medium" align="center"> -->
      <!-- <div  align="center"> -->
      <!-- <div :class="`text-black-${model}`"> -->

      <!-- <base-btn
          class="mr-0"
          square
          title="Go to top"
          @click="$vuetify.goTo(0)"
   >
    <v-icon>mdi-chevron-up</v-icon>
   </base-btn> -->

      <div class="black--font-weight-medium" align="center">
        <font size="2">
          박인호건강증진클리닉 | 대표 : 박인호 | 전화 : 02-545-7187 <br />
          서울특별시 강동구 강동대로53길 50, 301호 (성내동)<br />
          사업자등록번호 : 119-21-32873 </font
        ><br />
        <font size="2">
          <p style="padding:0px;font-weight: bold;">
            Copyright&nbsp;2002&nbsp;박인호건강증진클리닉 COSBODY&nbsp;all
            rights reserved.
          </p>
        </font>
      </div>

      <!-- <v-spacer></v-spacer> -->

      <!-- <router-link :to="_link" @click.native="callSomeFunc()">some link</router-link> -->

      <!-- <v-btn value="recent">
      <span>Recent</span>
      <v-icon>mdi-history</v-icon>
    </v-btn>

    <v-btn value="favorites">
      <span>Favorites</span>
      <v-icon>mdi-heart</v-icon>
    </v-btn>

    <v-btn value="nearby">
      <span>Nearby</span>
      <v-icon>mdi-map-marker</v-icon>
    </v-btn> -->
    </v-bottom-navigation>

    <!-- </v-container> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      response: [],
      errors: [],
      // bottomNav: "recent",
      bottomNav: true
      // model: 'caption',
      // sizes: [
      //   ['mdi-devices', 'all', 'caption'],
      //   ['mdi-cellphone-iphone', 'sm', 'body-2'],
      //   ['mdi-laptop', 'md', 'body-1'],
      //   ['mdi-monitor', 'lg', 'h6'],
      //   ['mdi-television', 'xl', 'h4'],
      // ]
    };
  },
  computed: {},
  methods: {},
  created() {}

  // export default {
  //    data: () => ({

  //     // bottomNav: "recent",
  //     bottomNav: true,
  //     model: 'caption',
  //     sizes: [
  //       ['mdi-devices', 'all', 'caption'],
  //       ['mdi-cellphone-iphone', 'sm', 'body-2'],
  //       ['mdi-laptop', 'md', 'body-1'],
  //       ['mdi-monitor', 'lg', 'h6'],
  //       ['mdi-television', 'xl', 'h4'],
  //     ]

  //   }),
};
</script>
