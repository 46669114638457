import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";

import vuetify from "./plugins/vuetify";
import store from "./store";
import materialdesignicons from "@mdi/font/css/materialdesignicons.css";
import robotofontface from "roboto-fontface/css/roboto/roboto-fontface.css";
import router from "./router";
import VueMobileDetection from "vue-mobile-detection";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VeeValidate from "vee-validate";
import IdleVue from "idle-vue";
import varConifg from "./js/variables.js";

import ImageUploader from "vue-image-upload-resize";
Vue.use(ImageUploader);

import "./js/imageResize.js";

import VuejsDialog from "vuejs-dialog";
Vue.use(VuejsDialog);

import "vuejs-dialog/dist/vuejs-dialog.min.css";

import "./plugins/base";

var stubArray = require("lodash.stubarray");
Vue.use(stubArray);

Vue.component("blog-post", {
  props: ["title"],
  template: "<h3>{{ title }}</h3>"
});

Vue.config.productionTip = false;

Vue.prototype.$pathUrl = "http://cosbody.com";

Vue.use(
  { VueMobileDetection },
  { BootstrapVue },
  { materialdesignicons },
  { robotofontface }
);
Vue.use(VeeValidate, { inject: false });

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  // idleTime: 600000, // 1000 -> 1 seconds 600000 -> 10 minutes
  idleTime: 3600000, // 1 hour = (milliseconds = 3,600,000 ms)
  startAtIdle: false
});

new Vue({
  beforeCreate: function() {
    Vue.prototype.$localFrontUrlPath = varConifg.localFrontUrlPath;
    Vue.prototype.$localBackendUrlPath = varConifg.localBackendUrlPath;
    Vue.prototype.$realUrlPath = varConifg.realUrlPath;
  },
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount("#app");
