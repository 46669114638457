import Vue from "vue";
import VueRouter from "vue-router";

/* pages */
import home from "./views/Home.vue";
import _11 from "./views/1-1.vue";
import _12 from "./views/1-2.vue";
import _13 from "./views/1-3.vue";
import _14 from "./views/1-4.vue";
import _21 from "./views/2-1.vue";
import _22 from "./views/2-2.vue";
import _23 from "./views/2-3.vue";
import _24 from "./views/2-4.vue";
import _31 from "./views/3-1.vue";
import _32 from "./views/3-2.vue";
import _33 from "./views/3-3.vue";
import _34 from "./views/3-4.vue";
import _41 from "./views/4-1.vue";

import Login from "./views/Login.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  // mode: "hash",
  mode: "history",
  base: process.env.BASE_URL,
  path: "/",
  redirect: "/home",
  meta: { requiresAuth: true },
  routes: [
    {
      path: "/bbsadd",
      name: "bbsadd",
      // lazy-loaded
      component: () => import("./views/BbsAdd.vue")
    },
    {
      path: "/bbsadd/:new",
      name: "bbsadd/new",
      // lazy-loaded
      component: () => import("./views/BbsAdd.vue")
    },
    {
      path: "/bbslist/:propcurrPage",
      name: "bbslist",
      // lazy-loaded
      component: () => import("./views/BbsList.vue")
    },
    {
      path: "/bbsdetail/:id/:propcurrPage",
      name: "bbsdetail",
      component: () => import("./views/BbsDetail.vue")
    },
    {
      path: "/bbsmodify/:id/:currPage",
      name: "bbsmodify",
      component: () => import("./views/BbsAdd.vue")
    },
    {
      path: "/",
      name: "home",
      component: home
    },
    {
      path: "/login",
      component: Login
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/1-1",
      name: "1-1",
      component: _11
    },
    {
      path: "/1-2",
      name: "1-2",
      component: _12
    },

    {
      path: "/1-3",
      name: "1-3",
      component: _13
    },
    {
      path: "/1-4",
      name: "1-4",
      component: _14
    },

    {
      path: "/2-1",
      name: "2-1",
      component: _21
    },

    {
      path: "/2-2",
      name: "2-2",
      component: _22
    },

    {
      path: "/2-3",
      name: "2-3",
      component: _23
    },

    {
      path: "/2-4",
      name: "2-4",
      component: _24
    },
    {
      path: "/3-1",
      name: "3-1",
      component: _31
    },

    {
      path: "/3-2",
      name: "3-2",
      component: _32
    },

    {
      path: "/3-3",
      name: "3-3",
      component: _33
    },

    {
      path: "/3-4",
      name: "3-4",
      component: _34
    },
    {
      path: "/4-1",
      name: "4-1",
      component: _41
    }
  ]
});

export default router;
