export default class User {
  constructor(
    id,
    userId,
    username,
    password,
    email,
    phoneNumber,
    loginWrongCnt
  ) {
    this.id = id;
    this.userId = userId;
    this.username = username;
    this.password = password;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.loginWrongCnt = loginWrongCnt;
  }
}
