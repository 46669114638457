<template>
  <!-- <v-app class="white lighten-4" > -->
  <div id="app">
    <v-app>
      <Navbar />
      <v-main class="mx-4 mb-4">
        <ModalIdle v-if="isIdle" />
        <router-view></router-view>
      </v-main>
      <Bottom />
      <!-- <core-cta /> -->
    </v-app>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Bottom from "./components/Bottom.vue";
import ModalIdle from "@/components/ModalIdle";

export default {
  name: "App",
  components: {
    Navbar,
    Bottom,
    CoreCta: () => import("@/components/Cta"),
    ModalIdle
  },
  data: () => ({
    idleTime: ""
  }),

  computed: {
    isIdle() {
      this.idleTime = this.$store.state.idleVue.isIdle;
      if (this.currentUser) {
        if (this.idleTime) {
          return true;
        }
      } else {
        return false;
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  created() {},
  methods: {}
};
</script>
